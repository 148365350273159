import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { use100vh } from 'react-div-100vh';
//icon
// import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { BsList, BsSearch } from 'react-icons/bs';
import { ReactComponent as Close } from '../../assets/close.svg';
// import { RiArrowLeftLine } from 'react-icons/ri';
// components
import FilterController from '../filterController';
import DistanceController from '../distanceController';
import NavigateBeforeIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';

import FilterListIcon from '@mui/icons-material/FilterList';

import { userInfo } from '../../reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';

import { Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { blue } from '@mui/material/colors';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../auth/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

interface TabletOrMoblieSidebarProps {
	codeFilter?: any;
	distance?: any;
	onChangeMode?: () => void;
	onSearch?: () => void;
	onClickFilterButton?: () => void;
	onClickDistanceButton?: () => void;
	detailMode?: boolean;
	clearDetailMode?: () => void;
}

const TabletOrMoblieSidebar = ({
	codeFilter,
	onClickFilterButton,
	distance,
	onClickDistanceButton,
	onSearch,
	detailMode,
	clearDetailMode,
}: TabletOrMoblieSidebarProps) => {
	let location = useLocation();
	const [active, setActive] = useState('');
	const [drawer, setDrawer] = useState(false);
	// 모바일 컨트롤
	const [mobileController, setMobileController] = useState({
		filter: false,
		distance: false,
	});
	const height = use100vh();

	const dispatch = useDispatch();
	const userInfoData = useSelector((state: any) => state.user);
	const { accounts } = useMsal();

	useEffect(() => {
		if(userInfoData?.kor_nm == null) {
			dispatch(userInfo({ account: accounts[0]['username'], kor_nm: accounts[0]['name'] }));
		}
	}, [accounts, dispatch, userInfoData.emp_id, userInfoData?.kor_nm]);

	useEffect(() => {
		if (location.pathname === '/') {
			setActive('home');
		} else if (location.pathname === '/caltex') {
			setActive('caltex');
		} else if (location.pathname === '/report') {
			setActive('report');
		} else if (location.pathname === '/panel') {
			setActive('panel');
		}
	}, [location]);

	const onClickMobileController = (value: string) => () => {
		if (active === 'home' && value === 'filter') {
			setMobileController({
				filter: !mobileController.filter,
				distance: mobileController.distance,
			});
		} else if (active === 'home' && value === 'distance') {
			setMobileController({
				filter: mobileController.filter,
				distance: !mobileController.distance,
			});
		}
	};

	return (
		<TabletOrMoblieSidebarWrap>
			<div className='main-bar'>
				<div className='main-bar-left'>
					{detailMode && (
						<button className='search-header-back' onClick={clearDetailMode}>
							<NavigateBeforeIcon fontSize='large' sx={{paddingRight: 2}}/>
						</button>
					)}
					<Link to='/' className='logo'>
						{/* <img alt='GS리테일' src='/sidebar-logo.png' /> */}
						<img alt='GS리테일' src='/gsretail-logo.png' />
					</Link>
				</div>

				<nav>
					<ul>
						<li>
							{active === 'home' && !detailMode && (
								<button onClick={onSearch}>
									<BsSearch className='search-svg' />
								</button>
							)}
							<button onClick={() => setDrawer((prev) => !prev)}>
								<BsList />
							</button>
						</li>
					</ul>
				</nav>
			</div>

			<CustomDrawer
				height={height}
				anchor='right'
				open={drawer}
				onClose={() => {
					setDrawer((prev) => !prev);
				}}
			>
				<DrawerBody>
					<div className='drawer-header'>
						<Close
							onClick={() => {
								setDrawer((prev) => !prev);
							}}
						/>
					</div>
					<nav>
						<Typography variant='body2' style={{ textAlign: 'left', paddingTop: "25px", paddingLeft: "20px", fontWeight: 700, fontSize: "17px", fontFamily: "'Noto Sans KR', sans-serif"}}>
							<AccountCircleIcon sx={{ color: blue[900], paddingRight: "5px" }}/>
							{userInfoData?.team_nm == null ? accounts[0]['name']: (userInfoData?.kor_nm + ' / ' + userInfoData?.team_nm)}
						</Typography>
						<button style={{margin:10, paddingLeft: "12px", fontWeight: 700, fontSize: "15px", color: 'GrayText'}}  onClick={() => {
							msalInstance.logoutRedirect();
						}}>
							<LogoutIcon fontSize='small' sx={{ color: 'GrayText', fontWeight: 700, w:1, h:1 }}/>Logout
							</button>
					</nav>
					<nav>
						<ul>
							<li>
								<Link to='/' className={active === 'home' ? 'active' : ''}>
									점포 지도
								</Link>
							</li>
							{userInfoData?.team_mng_yn === "Y" || userInfoData?.part_mng_yn === "Y"  || userInfoData?.sm_mng_yn === "Y" ? 
								<li>
									<Link to='/report' className={active === 'report' ? 'active' : ''}>
										담당점포 리뷰
									</Link>
								</li>
							: null}
						</ul>
					</nav>
					{active === 'home' && (
						<ul className='drawer-setting'>
							<li>
								<FilterListIcon sx={{paddingRight: 1, fontSize: "15px"}}/><div>점포 유형</div>
							</li>
							<li className='moblie-filter-controller'>
								<FilterController
									//@ts-ignore
									codeFilter={codeFilter}
									onClickFilterButton={onClickFilterButton}
								/>
							</li>
							<li>
								<FilterListIcon sx={{paddingRight: 1, fontSize: "15px"}}/>조회 범위
							</li>
							<li className='moblie-distance-controller'>
								<DistanceController
									//@ts-ignore
									distance={distance}
									onClickDistanceButton={onClickDistanceButton}
								/>
							</li>
						</ul>
					)}
				</DrawerBody>
			</CustomDrawer>
		</TabletOrMoblieSidebarWrap>
	);
};

export default TabletOrMoblieSidebar;

interface CustomDrawerType {
	height: any;
}

const CustomDrawer = styled(Drawer)<CustomDrawerType>`
	height: ${({ height }) => `${height}px !important;`};
	overflow-y: auto;
`;

const TabletOrMoblieSidebarWrap = styled.div`
	box-sizing: border-box;
	width: 100%;
	position: relative;
	box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%), 5px 0 15px 0 rgb(0 0 0 / 10%);
	.main-bar {
		box-sizing: border-box;
		width: 100%;
		display: flex;
		background-color: #fff;
		align-items: center;
		justify-content: space-between;
		padding: 0 18px;
		height: 60px;

		.main-bar-left {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 34px;
			> button {
				display: flex;
			}
		}

		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.logo img {
			width: 105px;
		}
		ul {
			display: flex;
			align-items: center;
			> li {
				svg {
					width: 26px;
					height: 26px;
					color: #414141;
				}
			}
		}
	}
	.search-svg {
		margin-right: 18px;
		width: 22px !important;
		height: 22px !important;
	}
`;

const DrawerBody = styled.div`
	.drawer-header {
		text-align: right;
		padding: 0 14px;
		height: 60px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-weight: 700;
		font-size: 17px;
	}

	ul:nth-child(1) {
		padding-top: 0;
	}

	.drawer-setting {
		> li {
			padding: 18px 24px 0px 24px;
			box-sizing: border-box;
			display: flex;
			justify-content: start;
			align-items: center;
			width: 244px;
			color: rgb(65, 65, 65);
			font-family: 'Noto Sans KR', sans-serif !important;
			font-size: 15px;
			line-height: 25px;
			font-weight: 500;
			color: grey;
		}
	}

	nav > ul {
		border-bottom: 1px solid rgb(229, 231, 235);
		> li {
			box-sizing: border-box;
			padding: 16px 24px;
			width: 244px;
			color: rgb(65, 65, 65);
			font-family: 'Noto Sans KR', sans-serif !important;
			font-size: 17px;
			line-height: 25px;
			font-weight: 700;
		}
	}
	nav {
		padding-bottom: 36px;
	}
	nav > ul li a,
	nav > ul li button {
		box-sizing: border-box;
		display: flex;
		width: 100%;
		justify-content: start;
		align-items: center;
		font-family: 'Noto Sans KR', sans-serif !important;
		font-size: 17px;
		line-height: 25px;

		&.active {
			color: #1473e6;
			font-weight: 700;

			svg path {
				stroke: #1473e6;
			}
		}
	}

	nav > ul li a svg,
	nav > ul li button svg {
		width: 20px;
		height: 20px;
		margin-right: 16px;
	}

	.moblie-filter-controller {
		padding: 10px 8px !important;
		> div {
			position: unset;
		}
	}

	.moblie-distance-controller {
		padding: 10px 8px !important;
		> div {
			position: unset;
		}
	}
`;
